const body = document.querySelector('body');

const getHour = () => {
  const date = new Date();
  return date.getHours();
}

const enableLightMode = () => {
  body.classList.remove('dark');
}

const enableDarkMode = () => {
  body.classList.add('dark');
}

const setMode = () => {
  const hour = getHour();

  if (hour >= 6 && hour < 18 ) {
    enableLightMode();
    return;
  }
  enableDarkMode();
}

setMode();

setInterval(setMode, 1000 * 60);
