import _throttle from 'lodash/throttle';

const body = document.querySelector('body');
const counterDiv = document.querySelector('.counter');

var circle = document.querySelector("circle");

var width = 800;

const circleR = 30;
const maxLeft = width - circleR * 2;
const maxTop = width - circleR * 2;

let animationReady = false;
let counter = 0;
let isCentered = false;

setInterval(() => {
  if (!isCentered) {
    return;
  }
  counter += 1;
  counterDiv.innerHTML = `Centered for ${counter} seconds`
}, 1000);


setTimeout(() => {
  animationReady = true
}, 4000);

const moveCircle = (percentLeft, percentTop) => {

  circle.setAttribute("cx", percentLeft * maxLeft + circleR)
  circle.setAttribute("cy", percentTop * maxTop + circleR)

  if (!animationReady) {
    return;
  }

  if (percentLeft > .49 && percentLeft < .51
    && percentTop > .49 && percentTop < .51) {
    if (isCentered == false){
      isCentered = true
      counter = 0
      body.classList.add('center');
    }
  } else {
    isCentered = false
    body.classList.remove('center');
  }
}

let foundMotion = false;

const mouseWatcher = event => {
  const height = window.innerHeight
  const percentLeft = (window.innerWidth - event.clientX) / window.innerWidth;
  const percentTop = (height - event.clientY) / height;
  moveCircle(percentLeft, percentTop)
}

const throttledMouseWatcher = _throttle(mouseWatcher, 10)

const motionWatcher = event => {
  if (!foundMotion) {
    window.removeEventListener('mousemove', throttledMouseWatcher);
  }

  foundMotion = true;

  const percentTop = (event.beta + 180) / 360;
  const percentLeft = (event.gamma + 90) / 180;

  moveCircle(percentLeft, percentTop)
}

const throttledMotionWatcher = _throttle(motionWatcher, 10)

window.addEventListener('mousemove', throttledMouseWatcher);
window.addEventListener('deviceorientation', throttledMotionWatcher);
